import { Component, ElementRef, OnDestroy } from '@angular/core';
import { Router} from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { datadogLogs } from '@datadog/browser-logs';
import { User as ddUser } from '@datadog/browser-core';

import { InactivityTimeoutService } from './core/inactivity-timeout/inactivity-timeout.service';
import { SecurityService } from './core/security/security.service';
import { UserJsVm } from './shared/generated/Administration/Models/Users/UserJsVm';
import { pcgSettings } from './shared/generated/pcg-settings';

/**
 * This is the application's root component.
 */
@Component({
	selector: 'pcg-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
	
	errorCache: any = {};
	// The following are error codes thrown as a result of
	// system security and should not be logged to datadog.
	// If you make changes here, also update the ErrorLoggingMiddleware.cs
	ignoredErrorCodes: number[] = [
		401   // Login redirect
		, 403 // Access denied
		, 466 // Notification.Confirm() Msg
		, 467 // 2fa not enabled
	];

	// Inactivity timeout needs to be injected here to have a constant timeout going in the background
	constructor(
		router: Router
		, elRef: ElementRef
		, private inactivityService: InactivityTimeoutService
		, private sec: SecurityService
		, private modalConfig: NgbModalConfig
	) {
		this.configureDatadog();
		this.configureModal(); // Default modal settings, such as disabling the animation
		sec.user$.subscribe(user => {
			const isLoggedIn = user 
				? true 
				: false;
			if (isLoggedIn) { elRef.nativeElement.classList.add('is-logged-in'); } 
			else { elRef.nativeElement.classList.remove('is-logged-in'); }
		});
		window['$router'] = router;
	}

	/** Set default ng-bootstrap modal options */
	configureModal() {
		// Disable ng bootstrap modal animations by default
		this.modalConfig.animation = false;
		this.modalConfig.ariaLabelledBy = 'modal-basic-title';
	}
	
	configureDatadog() {
		if (pcgSettings.mode.toLowerCase() !== 'development') {
			datadogLogs.setGlobalContext({ env: pcgSettings.mode });
			datadogLogs.setGlobalContextProperty('referrer', document.referrer);
			datadogLogs.logger.setLevel('warn');
	
			this.initDataDog();
			datadogLogs.setUser(this.getDatadogUser(this.sec.getUser()));
			
			this.sec.user$.subscribe(newUser => { 
				this.initDataDog();
				datadogLogs.setUser(this.getDatadogUser(newUser));
			});
		}
	}

	initDataDog() {
		datadogLogs.clearUser();
		const initConfig = datadogLogs.getInitConfiguration();
		if (initConfig == null) { // stop datadog init if it is already initialized
			datadogLogs.init({
				beforeSend: (log) => {
					// discard ignored error codes
					if (this.ignoredErrorCodes.includes(log?.http?.status_code)) { return false; }
				}
				, clientToken: "pub77451973018f724e8a9f58aa8d77985d"
				, trackingConsent: 'granted'
				, site: "us5.datadoghq.com"
				, service: "homer"
				, version: "0a6b19b71e"
				, env: pcgSettings.mode
				, forwardErrorsToLogs: true
				, sessionSampleRate: 100			
			});
			console.log("datadogLogs initialized!");
		}	
	}

	getDatadogUser(userJs: UserJsVm): ddUser {
		let ddUser : ddUser = { 
			id: userJs?.id.toString()
			, email: userJs?.email
			, name: `${userJs?.firstName} ${userJs?.lastName}` 
		}
		return ddUser;
	}

	ngOnDestroy(): void {
		this.inactivityService.stopTracking();
	}
}
